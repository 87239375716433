<script setup>
    import { Link } from "@inertiajs/vue3"

    const props = defineProps({
        href: {
            type: String,
            required: true,
        },
        asVueLink: {
            type: Boolean,
            default: true,
        },
        only: {
            type: Array,
            default: [],
        },
        except: {
            type: Array,
            default: [],
        },
        externalLink: {
            type: Boolean,
            default: false,
        },
    })
</script>

<template>
    <Link
        v-if="props.asVueLink === true"
        :href="href"
        :only="props.only"
        :except="props.except"
        :target="externalLink ? '_blank' : '_self'"
        class="inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-xs font-semibold uppercase tracking-widest text-gray-700 shadow-sm transition duration-150 ease-in-out hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-brand-primary focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-25 dark:border-gray-500 dark:bg-gray-800 dark:text-gray-300 dark:hover:bg-gray-700 dark:focus:ring-offset-gray-800"
    >
        <slot />
    </Link>
    <a
        v-else
        :href="href"
        :target="externalLink ? '_blank' : '_self'"
        class="inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-xs font-semibold uppercase tracking-widest text-gray-700 shadow-sm transition duration-150 ease-in-out hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-brand-primary focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-25 dark:border-gray-500 dark:bg-gray-800 dark:text-gray-300 dark:hover:bg-gray-700 dark:focus:ring-offset-gray-800"
    >
        <slot />
    </a>
</template>
